// extracted by mini-css-extract-plugin
export var container = "layout-module--container--YM7Lx";
export var blogPostContainer = "layout-module--blog-post-container--bqfnL";
export var navLinkItem = "layout-module--nav-link-item--31U8T";
export var navLinkText = "layout-module--nav-link-text--ExV28";
export var standardBulletedList = "layout-module--standard-bulleted-list--iFbTM";
export var footerLinks = "layout-module--footer-links--1ZTEu";
export var blogPostList = "layout-module--blog-post-list--8bZsb";
export var blogLink = "layout-module--blog-link--3eHkm";
export var blogDate = "layout-module--blog-date--o6cdI";
export var navLinks = "layout-module--nav-links--1VlO2";
export var copyright = "layout-module--copyright--2swj1";
export var navLinkItemActive = "layout-module--nav-link-item-active--3-cfc";
export var siteTitle = "layout-module--site-title--16llq";
export var content = "layout-module--content--3GWBe";
export var repeatingCounterRule = "layout-module--repeating-counter-rule--27uw2";